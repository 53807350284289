import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useGetDoctorOnDemandPrimarySymptoms from "../../../../../../hooks/useGetDoctorOnDemandPrimarySymptoms";
import {
  DoctorOnDemandPrimarySymptom,
  Membership,
} from "../../../../../../services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../../../../../services/feature-toggle-adapter";
import theme, { shadows } from "../../../../../../theme";
import { convertPxToRem } from "../../../../../../utils";
import DoctorOnDemandPrimarySymptomsScroller from "../../../DoctorOnDemandPrimarySymptomsScroller";
import MedicalAssistanceCTA from "../../../MedicalAssistanceCTA";
import MessagesCTA from "../../../MessagesCTA";
import DoctorOnDemandPrimarySymptomsModal from "../DoctorOnDemandPrimarySymptomsModal";

interface DoctorOnDemandDashboardHeaderProps {
  firstName: string;
  membershipData: {
    firstMembership: Membership;
    isMembershipsLoading: boolean;
  };
}

export default function DoctorOnDemandDashboardHeader({
  firstName,
  membershipData,
}: DoctorOnDemandDashboardHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [
    isDoctorOnDemandPrimarySymptomsModalOpen,
    setIsDoctorOnDemandPrimarySymptomsModalOpen,
  ] = useState(false);

  const {
    primarySymptoms,
    primarySymptomsFetchError,
    isPrimarySymptomsLoading,
  } = useGetDoctorOnDemandPrimarySymptoms();

  function onBellIconButtonClick() {
    navigate("/home/messages");
  }

  function onPrimarySymptomClick(primarySymptom: DoctorOnDemandPrimarySymptom) {
    navigate(
      `/home/get-medical-assistance/intercare?primarySymptomIds=${primarySymptom.id}`
    );
  }

  function onViewAllPrimarySymptomsClick() {
    setIsDoctorOnDemandPrimarySymptomsModalOpen(true);
  }

  return (
    <>
      <Box
        sx={{
          borderRadius: `0 0 ${convertPxToRem(15)}  ${convertPxToRem(15)}`,
          background: `linear-gradient(to top, ${theme.palette.primary.contrastText}, ${theme.palette.accent2.light}, ${theme.palette.accent2.light})`,
          position: "absolute",
          height: convertPxToRem(350),
          width: "100%",
          left: 0,
          top: 0,
          zIndex: 1,
          boxShadow: shadows.medium,
        }}
      ></Box>

      <Stack sx={{ zIndex: 2 }} mb={2}>
        {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_IN_APP_MESSAGING) && (
          <Stack display="flex" alignItems="end" position="relative">
            <MessagesCTA onClick={onBellIconButtonClick} />
          </Stack>
        )}
        <Stack spacing={1}>
          <Typography
            variant="body1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(20),
            }}
          >
            {firstName?.length > 0
              ? t("Home.title.withName", {
                  name: firstName,
                })
              : t("Home.title.withoutName")}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(24),
            }}
          >
            {t("Home.helpSubtitle")}
          </Typography>

          <Stack sx={{ zIndex: 2 }}>
            <MedicalAssistanceCTA membershipData={membershipData} />
          </Stack>
        </Stack>
        {!isPrimarySymptomsLoading &&
          !primarySymptomsFetchError &&
          primarySymptoms?.length > 0 && (
            <>
              <Stack
                mt={2}
                spacing={2}
                sx={{
                  width: "106%",
                  [theme.breakpoints.down("md")]: {
                    width: "100vw",
                  },
                  position: "relative",
                  left: `${convertPxToRem(-16)}`,
                }}
              >
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{
                    padding: `0 ${convertPxToRem(16)}`,
                  }}
                >
                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography fontWeight="bold" component="h2">
                      {t("DoctorOnDemand.primarySymptoms.homescreen.title")}
                    </Typography>
                    <Button
                      size="small"
                      variant="text"
                      sx={{ fontSize: convertPxToRem(14) }}
                      onClick={onViewAllPrimarySymptomsClick}
                    >
                      {t("DoctorOnDemand.primarySymptoms.homescreen.CTA")}
                    </Button>
                  </Stack>
                </Stack>

                <DoctorOnDemandPrimarySymptomsScroller
                  onPrimarySymptomClick={onPrimarySymptomClick}
                  primarySymptoms={primarySymptoms}
                />
              </Stack>

              <DoctorOnDemandPrimarySymptomsModal
                primarySymptoms={primarySymptoms}
                onPrimarySymptomClick={onPrimarySymptomClick}
                isOpen={isDoctorOnDemandPrimarySymptomsModalOpen}
                onDialogClose={() =>
                  setIsDoctorOnDemandPrimarySymptomsModalOpen(false)
                }
              />
            </>
          )}
      </Stack>
    </>
  );
}
