import { Stack, Typography, Box } from "@mui/material";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "../../../../../../services/feature-toggle-adapter";
import { convertPxToRem } from "../../../../../../utils";
import MessagesCTA from "../../../MessagesCTA";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import MedicalAssistanceCTA from "../../../MedicalAssistanceCTA";
import MedicalAssistanceInfoPanel from "../../../MedicalAssistanceInfoPanel";
import theme from "../../../../../../theme";
import { Membership } from "../../../../../../services/core-api-adapter";

interface DefaultDashboardHeaderProps {
  firstName: string;
  isPAYGProduct: boolean;
  membershipData: {
    firstMembership: Membership;
    isMembershipsLoading: boolean;
  };
}

export default function DefaultDashboardHeader({
  firstName,
  isPAYGProduct,
  membershipData,
}: DefaultDashboardHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onBellIconButtonClick() {
    navigate("/home/messages");
  }
  return (
    <>
      <Box
        sx={{
          borderRadius: `0 0 ${convertPxToRem(30)}  ${convertPxToRem(30)}`,
          background: `linear-gradient(to bottom, ${theme.palette.accent2[100]}, ${theme.palette.accent2.light}, ${theme.palette.accent2.main})`,
          position: "absolute",
          height: convertPxToRem(170),
          width: "100%",
          left: 0,
          top: 0,
          zIndex: 1,
        }}
      ></Box>

      <Stack sx={{ zIndex: 2 }}>
        {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_IN_APP_MESSAGING) && (
          <Stack display="flex" alignItems="end" position="relative">
            <MessagesCTA onClick={onBellIconButtonClick} />
          </Stack>
        )}
        <Stack spacing={1}>
          <Typography
            variant="body1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(20),
            }}
          >
            {firstName?.length > 0
              ? t("Home.title.withName", {
                  name: firstName,
                })
              : t("Home.title.withoutName")}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              fontSize: convertPxToRem(24),
            }}
          >
            {t("Home.helpSubtitle")}
          </Typography>
          <Stack>
            <Stack sx={{ zIndex: 2 }}>
              <MedicalAssistanceCTA membershipData={membershipData} />
            </Stack>
            {isPAYGProduct && (
              <Stack sx={{ marginTop: convertPxToRem(-6), zIndex: 1 }}>
                <MedicalAssistanceInfoPanel />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
