import { Box, Stack, Typography } from "@mui/material";
import theme from "../../../../theme";
import { convertPxToRem } from "../../../../utils";
import IconLoader from "../../../IconLoader";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useGetMessages from "../../../../hooks/useGetMessages";

interface MessagesCTAProps {
  onClick: () => void;
}

export default function MessagesCTA({ onClick }: MessagesCTAProps) {
  const { t } = useTranslation();

  const { unreadMessagesCount } = useGetMessages();

  const maxMessagesCount = useMemo(() => {
    if (unreadMessagesCount !== undefined && unreadMessagesCount > 9) {
      return "9+";
    }
    return unreadMessagesCount;
  }, [unreadMessagesCount]);

  const shouldDisplayMessagesCount = useMemo(() => {
    return unreadMessagesCount !== undefined && unreadMessagesCount > 0;
  }, [unreadMessagesCount]);

  return (
    <Stack
      component="button"
      onClick={onClick}
      sx={{ border: "none", cursor: "pointer", background: "transparent" }}
      aria-label={t("MessagesCTA.button.label")}
    >
      {shouldDisplayMessagesCount && (
        <Box
          bgcolor="primary.main"
          aria-label={t("Messages.HomeScreen.button.ariaLabel")}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: theme.spacing(2),
            width: theme.spacing(2),
            right: `-${convertPxToRem(3)}`,
            top: 0,
            px: 1,
            position: "absolute",
            borderRadius: "50%",
            fontSize: convertPxToRem(10),
            fontWeight: 700,
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            fontSize="inherit"
            color="primary.contrastText"
          >
            {maxMessagesCount}
          </Typography>
        </Box>
      )}
      <IconLoader
        icon="BellIcon"
        sx={{
          color: "neutral.main",
          fontSize: 30,
        }}
      />
    </Stack>
  );
}
