import { useState } from "react";
import {
  trackEvent,
  trackUserInteraction,
} from "../../../../services/analytics-adapter";
import useGetMemberConsultationRates from "../../../../hooks/useGetMemberConsultationRates";
import MedicalAssistanceConsultationRates from "./components/MedicalAssistanceConsulationRates";
import MedicalAssistanceInfoPanelModal from "./components/MedicalAssistanceInfoPanelModal";

export default function MedicalAssistanceInfoPanel() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { consultationRates, isConsultationRatesLoading } =
    useGetMemberConsultationRates();

  function onButtonClick() {
    trackEvent({
      event: "action.getMedicalAssistanceInfoPanelClicked",
      "getMedicalAssistanceInfoPanelClicked.source": "Home screen",
    });
    trackUserInteraction({
      linkText: "Home screen | getMedicalAssistanceInfoPanelClicked",
      linkIntent: "navigational",
      linkScope: "button",
    });
    setIsModalOpen(true);
  }
  return (
    <>
      {isConsultationRatesLoading === false && consultationRates.length > 0 && (
        <>
          <MedicalAssistanceConsultationRates
            consultationRates={consultationRates}
            onButtonClick={onButtonClick}
          />
          <MedicalAssistanceInfoPanelModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </>
      )}
    </>
  );
}
