import { Box, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import theme from "../../../../../../theme";
import { convertPxToRem } from "../../../../../../utils";
import IconLoader from "../../../../../IconLoader";
import LoadingSpinner from "../../../../../LoadingSpinner";
import useGetServiceOfferingUsage from "../../../../../../hooks/useGetServiceOfferingUsage";

export default function TileWithServiceUsage() {
  const { t } = useTranslation();

  const {
    isServiceOfferingUsageListLoading,
    availableServiceOfferingUsageCount,
    serviceOfferingUsageList,
  } = useGetServiceOfferingUsage();

  function hasUnlimitedHealthCheck() {
    const isUnlimitedHealthCheckAvailable =
      serviceOfferingUsageList.find(
        (serviceOffering) => serviceOffering.unlimited === true
      ) || null;
    return Boolean(isUnlimitedHealthCheckAvailable);
  }

  const maxAvailableHealthChecksCount = (count: number): any => {
    if (count > 9 || hasUnlimitedHealthCheck()) {
      return "9+";
    }
    return count;
  };

  return (
    <>
      {isServiceOfferingUsageListLoading && (
        <Stack height="100%" justifyContent="center" alignSelf="center">
          <LoadingSpinner />
        </Stack>
      )}

      {!isServiceOfferingUsageListLoading && (
        <>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="space-between"
            width="100%"
            textAlign="left"
          >
            <Typography variant="body2" fontWeight="bold">
              {t("HealthCheckTile.compatible.title")}
            </Typography>
            <IconLoader icon="ChevronNextIcon" color="primary" />
          </Stack>
          <Stack alignSelf="center">
            <Box position="relative">
              <IconLoader
                icon="UnuFaceWithRectangleIcon"
                sx={{
                  fontSize: convertPxToRem(70),
                }}
              />
              {(hasUnlimitedHealthCheck() ||
                availableServiceOfferingUsageCount > 0) && (
                <Box
                  bgcolor="primary.light"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    height: theme.spacing(4),
                    right: `-${convertPxToRem(10)}`,
                    bottom: convertPxToRem(1),
                    px: 1,
                    position: "absolute",
                    borderRadius: 10,
                    minWidth: theme.spacing(4),
                    fontSize: convertPxToRem(14),
                    fontWeight: 700,
                    outline: `${theme.palette.background.paper} solid ${convertPxToRem(4)}`,
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="background.paper"
                  >
                    {maxAvailableHealthChecksCount(
                      availableServiceOfferingUsageCount
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>
          <Stack alignSelf="center">
            <Typography variant="body2" sx={{ fontSize: convertPxToRem(11) }}>
              <Trans i18nKey="HealthCheckTile.compatible.subtitle"></Trans>
            </Typography>
          </Stack>
        </>
      )}
    </>
  );
}
