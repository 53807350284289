import {
  Button,
  Typography,
  Stack,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "../../../../../../theme";
import { convertPxToRem } from "../../../../../../utils";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "../../../../../DefaultDialog";
import IconLoader from "../../../../../IconLoader";

export default function MedicalAssistanceInfoPanelModal({
  isOpen,
  onClose,
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("MedicalAssistanceInfoPanel.modal.title")}
      actionButtons={
        <Button size="small" onClick={() => onClose()}>
          {t("common.close")}
        </Button>
      }
    >
      <Typography variant="body1" color="neutral.main" align="center">
        {t("MedicalAssistanceInfoPanel.modal.body")}
      </Typography>
      <Stack
        spacing={2}
        textAlign="left"
        padding={2}
        borderRadius={2.5}
        sx={{ backgroundColor: theme.palette.accent2[100] }}
      >
        <Stack
          component="ul"
          role="list"
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          }}
          aria-label={t(
            "MedicalAssistanceMoreInfo.OnlineNurseConsult.Accordion.title"
          )}
        >
          {(
            t<any, any, string[]>(
              "MedicalAssistanceInfoPanel.modal.listItems",
              {
                returnObjects: true,
              }
            ) as []
          ).map((listItemText: string) => (
            <ListItem
              role="listitem"
              sx={{ padding: "2px 0" }}
              key={listItemText}
            >
              <ListItemIcon sx={{ display: "contents" }}>
                <IconLoader
                  icon="CheckInCircleFilledIcon"
                  sx={{
                    fontSize: convertPxToRem(28),
                    color: "primary.main",
                    alignSelf: "center",
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ pl: 0.5 }}>
                <Typography variant="body2">{listItemText}</Typography>
              </ListItemText>
            </ListItem>
          ))}
        </Stack>
      </Stack>
    </DefaultDialog>
  );
}
