import { Avatar, Box, Stack, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  findPrimaryCardMemberInMembership,
  Member,
  Membership,
} from "../../../../services/core-api-adapter";
import { shadows } from "../../../../theme";
import MembershipStatusIndicator from "../../../MembershipStatusIndicator";
import ProductPartnerList from "../../../ProductPartnerList";

const HORIZONTAL_SPACING = 2;

interface Template3Props {
  membership: Membership;
}

export default function Template3({ membership }: Template3Props) {
  const { t } = useTranslation();

  const member: Member | null = findPrimaryCardMemberInMembership(membership);

  if (!member) {
    return <></>;
  }

  return (
    <Box
      component="li"
      aria-label={membership.productDetails.friendlyName}
      sx={{
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: shadows.light,
        height: "auto",
        maxWidth: (theme: Theme) => theme.spacing(75),
        width: "100%",
        mx: "auto",
        listStyle: "none",
      }}
    >
      <Box
        bgcolor="secondary.700"
        sx={{
          background: (theme: Theme) =>
            `linear-gradient(120.16deg, rgba(255, 255, 255, 0.15) 39.8%, rgba(255, 190, 64, 0) 97.41%), ${theme.palette.secondary[700]}`,
        }}
      >
        <Stack
          direction="row"
          sx={{
            borderBottom: (theme) =>
              `1px solid ${theme.palette.secondary[500]}`,
          }}
          pb={1}
          pt={2}
          mx={HORIZONTAL_SPACING}
          textAlign="left"
          alignItems="center"
          spacing={2}
        >
          <Avatar sx={{ width: 45, height: 45 }} />
          <Stack width="100%" spacing={0.5}>
            <Typography
              pb={0.5}
              variant="h5"
              aria-label={t("MembershipCard.memberNameHeadingLabel")}
            >
              {member.memberDetails.memberFullName}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MembershipStatusIndicator
                status={member.membershipDetails.status}
              />
              <Stack>
                <Typography variant="h5" color="inherit">
                  {
                    t("MembershipCard.beneficiaryCodeLabel", {
                      beneficiaryCode: member.membershipDetails.beneficiaryCode,
                    }) as any
                  }
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          justifyContent="space-around"
          px={HORIZONTAL_SPACING}
          pt={1}
          pb={2}
          sx={{ height: "100%" }}
          spacing={1}
        >
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack
              aria-label={t("MembershipCard.planTypeLabelExtended")}
              spacing={0.5}
            >
              <Typography variant="h6">
                {t("MembershipCard.planTypeLabelExtended")}
              </Typography>
              <Typography variant="h4">
                {membership.productDetails.friendlyName}
              </Typography>
            </Stack>
            <Stack spacing={0.5} textAlign="right">
              <Typography
                component="p"
                variant="h6"
                color="inherit"
                textAlign="inherit"
              >
                {t("MembershipCard.membershipNumberLabel")}
              </Typography>
              <Typography
                component="p"
                variant="h4"
                color="inherit"
                textAlign="inherit"
              >
                {member.membershipDetails.number}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Stack
        px={HORIZONTAL_SPACING}
        py={1}
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          background: (theme) => theme.palette.grey[50],
        }}
      >
        <ProductPartnerList product={membership.productDetails} />
      </Stack>
    </Box>
  );
}
