import { useEffect, useState } from "react";
import { Button, Divider, Stack, Typography, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Panel from "../Panel";
import MembershipCard from "../MembershipCard";
import LoadingSpinner from "../LoadingSpinner";
import {
  findDependentsInMembership,
  findMainMemberInMembership,
  getMembershipCallCentreServicePhoneChannel,
  Member,
  Membership,
} from "../../services/core-api-adapter";
import MembershipDependentsSummary from "../MembershipDependentsSummary";
import { useGlobalStore } from "../../store";
import theme from "../../theme";
import { trackEvent, trackUserInteraction } from "../../services/analytics-adapter";

interface MembershipDashboardPanelProps {
  membershipData: {
    firstMembership: Membership;
    memberships: Membership[];
    membershipsFetchError: boolean;
    isMembershipsLoading: boolean;
  };
}

export default function MembershipDashboardPanel({
  membershipData,
}: MembershipDashboardPanelProps) {
  const { t } = useTranslation();
  const { state } = useGlobalStore();
  const {
    firstMembership,
    memberships,
    membershipsFetchError,
    isMembershipsLoading,
  } = membershipData;
  const [member, setMember] = useState<Member | null>(null);
  const [callCenterNumber, setCallCenterNumber] = useState<string | null>("");
  const [dependents, setDependents] = useState<Member[]>([]);

  function onMembershipDetailsButtonClick() {
    trackEvent({
      event: "action.userNav",
      "userNav.destination": "myMembershipDetails",
      "userNav.via": "membershipCardButton",
    });
    trackUserInteraction({
      linkText: "Home screen card | My membership details",
      linkIntent: "navigational",
      linkScope: "button",
    });
  }

  useEffect(() => {
    setMember(
      findMainMemberInMembership(firstMembership, state.currentUser.memberId)
    );
    setCallCenterNumber(
      getMembershipCallCentreServicePhoneChannel(firstMembership)?.value || null
    );
    setDependents(findDependentsInMembership(firstMembership));
  }, [memberships]);

  return (
    <Stack
      component="article"
      aria-label={t("MembershipDashboardPanel.title")}
      spacing={2}
    >
      <Typography component="h2" fontWeight={600}>
        {t("MembershipDashboardPanel.title")}
      </Typography>

      <Panel>
        <Stack
          component="ul"
          spacing={2}
          alignItems={"center"}
          aria-label={t("MembershipDashboardPanel.title")}
        >
          {isMembershipsLoading && (
            <Stack alignItems="center">
              <LoadingSpinner />
            </Stack>
          )}

          {membershipsFetchError && (
            <Typography variant="h4" align="center">
              {t("common.somethingWentWrong")}
            </Typography>
          )}

          {!membershipsFetchError &&
            !isMembershipsLoading &&
            memberships.length === 0 && (
              <Typography variant="h4" align="center">
                {t("common.somethingWentWrong")}
              </Typography>
            )}

          {!isMembershipsLoading && memberships.length > 0 && (
            <>
              <MembershipCard membership={firstMembership} />
              {callCenterNumber && (
                <ListItem
                  sx={{
                    padding: 0,
                  }}
                >
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography
                      variant="body2"
                      color={theme.palette.neutral[500]}
                    >
                      {t("MembershipContactDetails.label")}
                    </Typography>
                    <Typography variant="body2" fontWeight={600}>
                      {callCenterNumber}
                    </Typography>
                  </Stack>
                  <Divider />
                </ListItem>
              )}

              {member && (
                <ListItem
                  sx={{
                    padding: 0,
                    gap: 2,
                    flexFlow: "column",
                  }}
                >
                  <MembershipDependentsSummary
                    dependentsCount={dependents.length}
                  />
                  <Button
                    size="small"
                    fullWidth
                    component={Link}
                    to={`/home/memberships/${member.membershipDetails.id}`}
                    onClick={onMembershipDetailsButtonClick}
                  >
                    {t("MembershipDashboardPanel.viewButton")}
                  </Button>
                </ListItem>
              )}
            </>
          )}
        </Stack>
      </Panel>
    </Stack>
  );
}
