import {
  Stack,
  Typography,
  Divider,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { GetMemberConsultationRatesItem } from "../../../../../../services/core-api-adapter";
import theme, { shadows } from "../../../../../../theme";
import { convertPxToRem } from "../../../../../../utils";
import IconLoader from "../../../../../IconLoader";
import { Trans, useTranslation } from "react-i18next";

interface MedicalAssistanceConsultationRatesProps {
  consultationRates: GetMemberConsultationRatesItem[];
  onButtonClick: () => void;
}

export default function MedicalAssistanceConsultationRates({
  consultationRates,
  onButtonClick,
}: MedicalAssistanceConsultationRatesProps) {
  const { t } = useTranslation();

  return (
    <>
      <Accordion
        disableGutters={true}
        aria-label={t("MedicalAssistanceInfoPanel.ariaLabel")}
        style={{
          borderRadius: `0 0 ${convertPxToRem(8)} ${convertPxToRem(8)}`,
        }}
        sx={{
          paddingTop: convertPxToRem(6),
          background: theme.palette.background.paper,
          boxShadow: shadows.light,
        }}
      >
        <AccordionSummary
          sx={{ py: 1, minHeight: convertPxToRem(50) }}
          expandIcon={<IconLoader icon="ChevronDownIcon" color="primary" />}
        >
          <Stack
            display="flex"
            flexDirection="row"
            gap={1}
            justifyContent="space-between"
          >
            <Typography variant="body1" fontWeight="bold">
              <Trans
                i18nKey="MedicalAssistanceInfoPanel.title"
                values={{
                  chargeCode1: consultationRates[0].amountFormatted,
                  chargeCode2: consultationRates[1].amountFormatted,
                }}
              >
                <Typography
                  component="span"
                  variant="body1"
                  fontWeight="bold"
                  color="primary"
                ></Typography>
              </Trans>
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ py: 0 }}>
          <Divider />
          <Stack spacing={0.5} py={1}>
            {consultationRates.map(
              (
                listItem: GetMemberConsultationRatesItem,
                listItemIndex: number
              ) => (
                <Stack
                  key={listItem.chargeCode + listItemIndex}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography variant="body2">
                    {t(
                      `MedicalAssistanceInfoPanel.${listItem.chargeCode}.title` as any
                    )}
                  </Typography>
                  <Typography color="primary.main" fontWeight="bold">
                    {listItem.amountFormatted}
                  </Typography>
                </Stack>
              )
            )}
          </Stack>
          <Divider />
          <Stack>
            <Button
              variant="text"
              onClick={onButtonClick}
              size="small"
              sx={{ fontSize: convertPxToRem(14), alignSelf: "center" }}
            >
              {t("MedicalAssistanceInfoPanel.modal.title")}
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
