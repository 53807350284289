import { Box, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { convertPxToRem } from "../../../../../../utils";
import IconLoader from "../../../../../IconLoader";

export default function Default() {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignContent="space-between"
        width="100%"
        textAlign="left"
      >
        <Typography variant="body2" fontWeight="bold">
          {t("HealthCheckTile.compatible.title")}
        </Typography>
        <IconLoader icon="ChevronNextIcon" color="primary" />
      </Stack>
      <Stack alignSelf="center">
        <Box position="relative">
          <IconLoader
            icon="UnuFaceWithRectangleIcon"
            sx={{
              fontSize: convertPxToRem(70),
            }}
          />
        </Box>
      </Stack>
      <Stack alignSelf="center">
        <Typography variant="body2" sx={{ fontSize: convertPxToRem(11) }}>
          <Trans i18nKey="HealthCheckTile.compatible.subtitle"></Trans>
        </Typography>
      </Stack>
    </>
  );
}
