import { Stack } from "@mui/material";
import GetPayGMedicalAssistanceCTAButton from ".";

export default function GetMedicalAssistanceCTAButtonExamples() {
  return (
    <Stack spacing={2}>
      <GetPayGMedicalAssistanceCTAButton />
    </Stack>
  );
}
