import { Trans, useTranslation } from "react-i18next";
import SlideUpDialog from "../../../../../SlideUpDialog";
import { Stack, Typography } from "@mui/material";
import { convertPxToRem } from "../../../../../../utils";
import DoctorOnDemandPrimarySymptomsPill from "../../../../../DoctorOnDemandPrimarySymptomsPill";
import { DoctorOnDemandPrimarySymptom } from "../../../../../../services/core-api-adapter";

interface DoctorOnDemandPrimarySymptomsModalProps {
  isOpen: boolean;
  onDialogClose: () => void;
  primarySymptoms: DoctorOnDemandPrimarySymptom[];
  onPrimarySymptomClick: (primarySymptom: DoctorOnDemandPrimarySymptom) => void;
}

export default function DoctorOnDemandPrimarySymptomsModal({
  isOpen,
  onDialogClose,
  primarySymptoms,
  onPrimarySymptomClick,
}: DoctorOnDemandPrimarySymptomsModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <SlideUpDialog
        isOpen={isOpen}
        onClose={onDialogClose}
        label={t("DoctorOnDemand.primarySymptoms.modal.label")}
      >
        <Stack textAlign="center" spacing={3}>
          <Typography variant="h2">
            {t("DoctorOnDemand.primarySymptoms.modal.title")}
          </Typography>
          <Typography variant="h4" fontWeight={400}>
            <Trans i18nKey="DoctorOnDemand.primarySymptoms.modal.subtitle"></Trans>
          </Typography>
          <Stack spacing={2}>
            {primarySymptoms.map((primarySymptom) => (
              <DoctorOnDemandPrimarySymptomsPill
                key={primarySymptom.id}
                onClick={() => onPrimarySymptomClick(primarySymptom)}
                primarySymptom={primarySymptom}
                titleStyles={{
                  fontSize: convertPxToRem(16),
                  fontWeight: 600,
                }}
                shouldShowSubtitle={true}
                shouldShowArrow={true}
              />
            ))}
          </Stack>
        </Stack>
      </SlideUpDialog>
    </>
  );
}
