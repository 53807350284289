import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HealthProfileSuccessResponseData,
  Member,
  Membership,
  findMainMemberInMembership,
} from "../../../../services/core-api-adapter";
import { useGlobalStore } from "../../../../store";
import { isFaceScanCompatibleDevice } from "../../../../utils";
import BenefitsTile from "../BenefitsTile";
import HealthCheckTile from "../HealthCheckTile";
import HealthProfileTile from "../HealthProfileTile";
import MyPeopleTile from "../MyPeopleTile";

interface TiledQuickLinksProps {
  healthProfileData: {
    healthProfile: HealthProfileSuccessResponseData | null;
    isHealthProfileLoading: boolean;
    healthProfileFetchError: boolean;
  };
  membershipData: {
    firstMembership: Membership;
    membershipsFetchError: boolean;
    isMembershipsLoading: boolean;
  };
  isPAYGProduct: boolean;
}

export default function TiledQuickLinks({
  healthProfileData,
  membershipData,
  isPAYGProduct,
}: TiledQuickLinksProps) {
  const { t } = useTranslation();
  const { state } = useGlobalStore();

  const { healthProfile, isHealthProfileLoading, healthProfileFetchError } =
    healthProfileData;

  const { firstMembership, membershipsFetchError, isMembershipsLoading } =
    membershipData;

  const [member, setMember] = useState<Member | null>(null);

  const [dependents, setDependents] = useState<Member[]>([]);

  useEffect(() => {
    setMember(
      findMainMemberInMembership(firstMembership, state.currentUser.memberId)
    );
    setDependents(firstMembership.members);
  }, [firstMembership]);

  function checkFaceScanCompatibility(): boolean {
    const {
      isIOSWithNonSafariBrowser,
      isAndroidWithNonChromeOrSamsungBrowser,
    } = isFaceScanCompatibleDevice();
    return !(
      isIOSWithNonSafariBrowser || isAndroidWithNonChromeOrSamsungBrowser
    );
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography component="h2" fontWeight={600}>
          {t("TiledQuickLinks.title")}
        </Typography>

        <Stack
          direction="row"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
          gap={2}
        >
          <HealthCheckTile
            isPAYGProduct={isPAYGProduct}
            isCompatibleDevice={checkFaceScanCompatibility()}
          />
          <HealthProfileTile
            lastUpdatedDate={healthProfile?.lastUpdatedAt}
            healthScore={Number(healthProfile?.healthScoreValue) || 0}
            isLoading={isHealthProfileLoading}
            isError={healthProfileFetchError}
          />
          <BenefitsTile />
          <MyPeopleTile
            mainMember={member}
            dependentsCount={dependents.length}
            isLoading={isMembershipsLoading}
            isError={membershipsFetchError}
          />
        </Stack>
      </Stack>
    </>
  );
}
