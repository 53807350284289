import { Box, Button, Stack, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import theme, { shadows } from "../../theme";
import { convertPxToRem } from "../../utils";
import {
  Product,
  findMainMemberProductPremium,
} from "../../services/core-api-adapter";

import IconLoader from "../IconLoader";
import { trackEvent } from "../../services/analytics-adapter";

const panelStyling = {
  borderRadius: 2,
  overflow: "hidden",
  backgroundColor: theme.palette.secondary.main,
  background: `linear-gradient(120.16deg, rgba(255, 255, 255, 0.15) 39.8%, rgba(255, 190, 64, 0) 97.41%), ${theme.palette.secondary.main}`,
  boxShadow: shadows.light,
  height: "auto",
  position: "relative",
  maxWidth: (theme: Theme) => theme.spacing(75),
  width: "100%",
  mx: "auto",
};

const backgroundPatternStyling = {
  fontSize: convertPxToRem(200),
  position: "absolute",
  overflow: "hidden",
  top: theme.spacing(-4),
  right: theme.spacing(-4),
};

interface MarketplaceDashboardCardProps {
  products: Product[];
}

export default function MarketplaceDashboardCard({
  products,
}: MarketplaceDashboardCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const mainMemberProductPremium = findMainMemberProductPremium(products?.[0]);

  function onExplorePlansButtonClick() {
    trackEvent({
      event: "action.marketplaceVisited",
      "marketplace.source": "homeScreenCallToAction",
    });
    return navigate("/home/marketplace");
  }

  return (
    <Stack
      component="article"
      aria-label={t("MarketplaceDashboardPanel.title")}
      sx={{
        ...panelStyling,
      }}
    >
      <IconLoader
        icon="Pattern3"
        color="info"
        opacity="40%"
        sx={{
          ...backgroundPatternStyling,
        }}
      />

      <Stack spacing={2} sx={{ p: 2, position: "relative", zIndex: 2 }}>
        <Typography variant="h2">
          {t("MarketplaceDashboardPanel.banner.title")}
        </Typography>

        <Box
          sx={{
            borderRadius: 2,
            p: 1,
            background: (theme) => theme.palette.grey[50],
            width: "45%",
          }}
          display="flex"
          justifyContent="center"
        >
          <Typography letterSpacing={2} component="p" variant="h6">
            {t("MarketplaceDashboardPanel.banner.callout")}
          </Typography>
        </Box>

        <Typography variant="h4">
          {t("MarketplaceDashboardPanel.productListing.description", {
            monthlyPrice: mainMemberProductPremium?.premiumFormatted,
            interval: t(
              `PaymentInterval.${mainMemberProductPremium?.interval as any}.label` as any
            ),
          })}
        </Typography>
      </Stack>

      <Stack
        sx={{
          background: (theme) => theme.palette.grey[50],
        }}
      >
        <Button
          variant="text"
          fullWidth
          style={{
            justifyContent: "space-between",
          }}
          endIcon={<IconLoader icon="ChevronNextIcon" sx={{ scale: "2" }} />}
          onClick={onExplorePlansButtonClick}
        >
          {t("common.explorePlans")}
        </Button>
      </Stack>
    </Stack>
  );
}
