import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { trackEvent, trackUserInteraction } from "../../../../services/analytics-adapter";
import {
  Membership,
  getMembershipConfig,
} from "../../../../services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../../../services/feature-toggle-adapter";
import ButtonWithLoadingState from "../../../ButtonWithLoadingState";
import GetPayGMedicalAssistanceCTAButton from "./components/GetMedicalAssistanceCTAButton";
import IconLoader from "../../../IconLoader";

interface MedicalAssistanceCTAProps {
  membershipData: {
    firstMembership: Membership;
    isMembershipsLoading: boolean;
  };
}

export default function MedicalAssistanceCTA({
  membershipData,
}: MedicalAssistanceCTAProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { firstMembership, isMembershipsLoading } = membershipData;
  const [isGetMedicalAssistanceSupported, setIsGetMedicalAssistanceSupported] =
    useState<boolean | null>(null);
  const [isIntercareSupported, setIsIntercareSupported] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    if (!isMembershipsLoading) {
      setIsGetMedicalAssistanceSupported(
        getMembershipConfig(firstMembership)?.isGetMedicalAssistanceSupported ||
          false
      );
      setIsIntercareSupported(
        (isFeatureEnabled(
          FeatureToggleIdentifier.ENABLE_INTERCARE_MEDICAL_ASSISTANCE
        ) &&
          getMembershipConfig(firstMembership)?.isIntercareSupported) ||
          false
      );
    }
  }, [firstMembership, isMembershipsLoading]);

  function onGetMedicalAssistanceClick() {
    trackEvent({
      event: "action.getMedicalAssistanceInitiated",
      "getMedicalAssistance.source": "Home screen",
    });

    trackUserInteraction({
      linkText: "Home screen | getMedicalAssistanceInitiated",
      linkIntent: "navigational",
      linkScope: "button",
    });

    navigate("/home/get-medical-assistance/prompt", {
      state: {
        from: location,
      },
    });
  }

  return (
    <>
      {(isMembershipsLoading || isGetMedicalAssistanceSupported === null) && (
        <ButtonWithLoadingState />
      )}

      {isIntercareSupported === true && <GetPayGMedicalAssistanceCTAButton />}

      {isGetMedicalAssistanceSupported === true &&
        isIntercareSupported === false && (
          <>
            <Button
              onClick={onGetMedicalAssistanceClick}
              aria-label={t("Home.GetMedicalAssistanceCTA")}
              color="primary"
              size="large"
              fullWidth
              endIcon={<IconLoader icon="ArrowInCircleIcon" />}
              style={{ justifyContent: "space-between" }}
            >
              <Stack spacing={1} textAlign="left">
                <Typography
                  component="p"
                  variant="h4"
                  color="primary.contrastText"
                >
                  {t("Home.GetMedicalAssistanceCTA")}
                </Typography>
              </Stack>
            </Button>
          </>
        )}

      {isGetMedicalAssistanceSupported === false &&
        isIntercareSupported === false && (
          <Button onClick={onGetMedicalAssistanceClick}>
            {t("Home.NeedMedicalAssistanceCTA")}
          </Button>
        )}
    </>
  );
}
