import { Membership } from "../../../../services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../../../services/feature-toggle-adapter";
import DefaultDashboardHeader from "./components/Default";
import DoctorOnDemandDashboardHeader from "./components/DoctorOnDemand";

interface DashboardHeaderProps {
  firstName: string;
  isPAYGProduct: boolean;
  membershipData: {
    firstMembership: Membership;
    isMembershipsLoading: boolean;
  };
}

export default function DashboardHeader({
  firstName,
  isPAYGProduct,
  membershipData,
}: DashboardHeaderProps) {
  return (
    <>
      {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_DOCTOR_ON_DEMAND) &&
      isPAYGProduct ? (
        <DoctorOnDemandDashboardHeader
          firstName={firstName}
          membershipData={membershipData}
        />
      ) : (
        <DefaultDashboardHeader
          firstName={firstName}
          isPAYGProduct={isPAYGProduct}
          membershipData={{
            firstMembership: membershipData.firstMembership,
            isMembershipsLoading: membershipData.isMembershipsLoading,
          }}
        />
      )}
    </>
  );
}
