import { MemberConsultationChargeCode } from "../../../../services/core-api-adapter";
import MedicalAssistanceConsultationRates from "./components/MedicalAssistanceConsulationRates";

export default function MedicalAssistanceInfoPanelExamples() {
  return (
    <MedicalAssistanceConsultationRates
      consultationRates={[
        {
          chargeCode: MemberConsultationChargeCode.IN_PERSON_DOCTOR_CONSULT,
          amount: "R499.00",
          amountFormatted: "R499",
        },
        {
          chargeCode: MemberConsultationChargeCode.ONLINE_DOCTOR_CONSULTATION,
          amount: "R179.00",
          amountFormatted: "R179",
        },
        {
          chargeCode: MemberConsultationChargeCode.CHAT_TO_A_NURSE,
          amount: "R95.00",
          amountFormatted: "R95",
        },
      ]}
      onButtonClick={() => console.log("Button clicked")}
    />
  );
}
