import { Avatar, Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import { convertPxToRem } from "../../utils";

interface MembershipDependentsSummaryProps {
  dependentsCount: number;
  mainMemberAvatarUrl?: string;
}

export default function MembershipDependentsSummary({
  dependentsCount,
  mainMemberAvatarUrl,
}: MembershipDependentsSummaryProps) {
  const { t } = useTranslation();
  const badgeOffsetLeft = theme.spacing(-2);

  return (
    <>
      {dependentsCount > 0 && (
        <Stack
          component="article"
          aria-label={t("MembershipDependentsSummary.label")}
          direction="row"
          alignItems="flex-start"
          width="100%"
          spacing={1}
        >
          <Avatar
            src={mainMemberAvatarUrl}
            sx={{
              width: theme.spacing(6),
              height: theme.spacing(6),
              zIndex: 2,
            }}
          />
          <Box
            bgcolor="primary.500"
            color="background.paper"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: theme.spacing(4),
              left: badgeOffsetLeft,
              px: 1,
              position: "relative",
              borderRadius: 10,
              minWidth: theme.spacing(4),
              fontSize: convertPxToRem(14),
              fontWeight: 700,
            }}
          >
            +{dependentsCount}
          </Box>
          <Typography
            variant="h5"
            alignSelf="center"
            sx={{
              position: "relative",
              left: badgeOffsetLeft,
            }}
          >
            {t("MembershipDependentsSummary.dependentCount.title", {
              count: dependentsCount,
            })}
          </Typography>
        </Stack>
      )}
    </>
  );
}
