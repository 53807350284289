import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../services/feature-toggle-adapter";
import useGetProductList from "../../hooks/useGetProductList";
import Panel from "../Panel";
import MarketplaceDashboardCard from "../MarketplaceDashboardCard";
import { useGlobalStore } from "../../store";
import {
  findNonFreePremiumProducts,
  sortProducts,
} from "../../services/core-api-adapter";

export default function MarketplaceDashboardPanel() {
  const { t } = useTranslation();

  const { state } = useGlobalStore();
  const { products, productsFetchError, isProductsLoading } =
    useGetProductList();

  const pricedProducts = products.filter((product) =>
    findNonFreePremiumProducts(product)
  );

  const sortedProducts = sortProducts(pricedProducts);

  return isFeatureEnabled(FeatureToggleIdentifier.ENABLE_MARKETPLACE) &&
    state.currentUser.isRetailMember === true &&
    !isProductsLoading &&
    sortedProducts.length > 1 ? (
    <>
      <Stack spacing={2}>
        <Typography component="h2" fontWeight={600}>
          {t("MarketplaceDashboardPanel.title")}
        </Typography>

        <MarketplaceDashboardCard products={sortedProducts} />
      </Stack>
    </>
  ) : (
    <>
      {productsFetchError && (
        <>
          <Typography variant="h4">
            {t("MarketplaceDashboardPanel.title")}
          </Typography>

          <Panel>
            <Stack
              component="article"
              aria-label={t("MarketplaceDashboardPanel.title")}
            >
              <Typography variant="h4" align="center">
                {t("common.somethingWentWrong")}
              </Typography>
            </Stack>
          </Panel>
        </>
      )}
    </>
  );
}
