import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import useGetHealthProfile from "../../hooks/useGetHealthProfile";
import useGetMemberships from "../../hooks/useGetMemberships";
import useGetProductType from "../../hooks/useGetProductType";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../services/feature-toggle-adapter";
import { useGlobalStore } from "../../store";
import {
  defaultFullscreenPageStyling,
  pageWithBottomNavigationStyling,
} from "../../theme";
import BottomNavigation from "../BottomNavigation";
import LifestyleAndAdviceSection from "./components/LifestyleAndAdviceSection";
import MarketplaceDashboardPanel from "../MarketplaceDashboardPanel";
import MembershipDashboardPanel from "../MembershipDashboardPanel";
import DashboardHeader from "./components/DashboardHeader";
import TiledQuickLinks from "./components/TiledQuickLinks";

export default function Dashboard() {
  const navigate = useNavigate();
  const { state, dispatch } = useGlobalStore();
  const { healthProfile, isHealthProfileLoading, healthProfileFetchError } =
    useGetHealthProfile();

  const {
    firstMembership,
    memberships,
    membershipsFetchError,
    isMembershipsLoading,
  } = useGetMemberships();

  const { isPAYGProduct } = useGetProductType(firstMembership);

  const firstName = state.currentUser.firstName;

  useEffect(() => {
    const controller = new AbortController();

    if (!state.hasMemberRequiredActionsBeenCompleted) {
      navigate("/member-required-actions");
    } else if (state.redirectLink) {
      navigate(state.redirectLink);
      dispatch({
        type: "CLEAR_REDIRECT_LINK",
      });
    }

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {state.hasMemberRequiredActionsBeenCompleted ? (
        <Stack
          sx={{
            ...defaultFullscreenPageStyling,
            ...pageWithBottomNavigationStyling,
            position: "relative",
          }}
        >
          <Stack mb={2}>
            <DashboardHeader
              isPAYGProduct={isPAYGProduct}
              firstName={firstName}
              membershipData={{
                firstMembership,
                isMembershipsLoading,
              }}
            />
          </Stack>

          <Stack spacing={3} zIndex={2}>
            <TiledQuickLinks
              healthProfileData={{
                healthProfile,
                isHealthProfileLoading,
                healthProfileFetchError,
              }}
              membershipData={{
                firstMembership,
                membershipsFetchError,
                isMembershipsLoading,
              }}
              isPAYGProduct={isPAYGProduct}
            />

            {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_CHAT_BOT) && (
              <LifestyleAndAdviceSection />
            )}
          </Stack>

          <Stack mt={4} spacing={5}>
            <MembershipDashboardPanel
              membershipData={{
                firstMembership,
                memberships,
                membershipsFetchError,
                isMembershipsLoading,
              }}
            />

            <MarketplaceDashboardPanel />
          </Stack>

          <BottomNavigation />
        </Stack>
      ) : null}
    </>
  );
}
